import { take } from "lodash";

/**
 * Gets the first name, technically gets all words leading up to the last
 * Example: "Blake Robertson" --> { first_name: "Blake", last_name: "Robertson" }
 * Example: "Blake Andrew Robertson" --> { first_name: "Blake Andrew", last_name: "Robertson" }
 * Example: "Blake" --> { first_name: "Blake", last_name: null }
 * Example: "" --> { first_name: null, last_name: null }
 * @param {string} name
 * @returns {Object} {first_name, last_name}
 */
export const split = (name: string) => {
  if (!name) return {};
  const names = name.split(" ");
  let first_name = name;
  let last_name: string | undefined;

  if (names.length > 1) {
    last_name = names.pop();
    first_name = names.join(" ");
  }

  return { first_name, last_name };
};

export const nameInitials = (name?: string) => {
  if (name) {
    const n = name.split(" ").map((n) => (n ? n[0].toUpperCase() : ""));
    return take(n, 2).join("");
  }
};
