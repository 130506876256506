<template>
  <v-avatar v-bind="$props">
    <ImageField v-if="img" :image="img" :alt="name" />
    <template v-else-if="initials">{{ initials }}</template>
    <Icon v-else :name="icon" :size="_iconSize" />
    <slot></slot>
  </v-avatar>
</template>

<script lang="ts">
import { isString } from "lodash";
import { defineComponent } from "vue";
import { VAvatar } from "vuetify/components";

export default defineComponent({
  name: "UserAvatar",
  props: {
    ...VAvatar.props,
    name: { type: String },
    icon: { type: String, default: "heroicons:user" },
    iconSize: { type: [Number, String] },
    avatar: { type: [String, Object] },
  },
  extends: VAvatar,
  setup(props) {
    const initials = computed(() => nameInitials(props.name));

    return {
      initials,
    };
  },
  computed: {
    _iconSize() {
      if (this.iconSize) {
        return this.iconSize;
      }
      return this.size ? Number(this.size) * 0.73 : 25;
    },
    img() {
      if (this.avatar) {
        if (isString(this.avatar)) {
          return {
            src: this.avatar,
          };
        }

        return this.avatar;
      }
    },
  },
});
</script>
